<template>
  <el-dialog
    :visible="dialogVisible"
    title="改派其他待提交车辆"
    width="90%"
    top="6vh"
    :destroy-on-close="true"
    @close="close"
  >
    <div class="carrier-report-wrap">
      <div class="card-title">承接信息</div>
      <div class="flex">
        <div class="info-item">
          <div class="label">承接价格：</div>
          <div class="value"><span>{{ originInfo.acceptPrice }} {{ $CONSTANT.transportPriceUnitMap.O[originInfo.unit] }}</span></div>
        </div>
        <div class="info-item">
          <div class="label">承接车数：</div>
          <div class="value"><span>{{ originInfo.acceptCarNumber }}</span> 车</div>
        </div>
      </div>
      <div class="card-title">上报车辆</div>
      <div class="flex">
        <el-col :span="5" class="flex-c">
          <div class="label bold-text">总计： </div>
          <div class="value"> {{ reportList.length||"-" }} 车</div>
        </el-col>
        <el-col :span="12" class="flex-c">
          <div class="label bold-text">找车指导价：</div>
          <div v-if="+pushSetBaseInfo.priceType===1" class="value">
            <span class="mr10">运价</span><span>{{ pushSetBaseInfo.price||'-' }} {{ $CONSTANT.transportPriceUnitMap.O[+pushSetBaseInfo.priceUnit] }}</span>
            <span :class="['ml10',$CONSTANT.taxColorMap[+pushSetBaseInfo.needTax]]">{{ $CONSTANT.taxMap[+pushSetBaseInfo.needTax]||'-' }}</span>
            <span class="grey-c fz_12 ml10">预估利润率 {{ profit }}%</span>
          </div>
          <div v-else class="value">
            <span class="mr10">信息费</span><span>{{ pushSetBaseInfo.price||'-' }} 元/车</span>
            <span :class="['ml10',$CONSTANT.taxColorMap[+pushSetBaseInfo.needTax]]">{{ $CONSTANT.taxMap[+pushSetBaseInfo.needTax]||'-' }}</span>
            <span class="grey-c fz_12 ml10">预估利润率 {{ InfoProfit }}%</span>
          </div>
        </el-col>
        <el-col :span="5">
          <el-input v-model="form.truckNo" placeholder="输入车牌号/姓名">
            <i slot="suffix" class="cursor hover-main el-input__icon el-icon-search" @click="search" />
          </el-input>
        </el-col>
      </div>
      <div class="g-search-form-table">
        <Table
          :table-cols="tableCols"
          :table-data="reportList"
          :show-one-selection-change="true"
          :one-selection.sync="selectData"
          :show-pagination="false"
        >
          <template v-slot="{ col, row }">
            <div v-if="col.prop === 'price'" class="ellipsis">
              <span class="mr5">{{ $parseNumber(row.price) }}</span>
              <span :class="$CONSTANT.taxColorMap[+row.needTax]">{{ $CONSTANT.taxMap[+row.needTax]||'-' }}</span>
            </div>
            <div v-if="col.prop === 'infoPrice'" class="ellipsis">
              <span>{{ +row.msgPriceType===2?$parseNumber(row.msgPrice):'-' }}元/车</span>
            </div>
            <!-- <div v-if="col.prop === 'payFast'" class="ellipsis">
            <span :class="$CONSTANT.taxColorMap[+row.payFast]" class="mr5">{{ $CONSTANT.landMap[+row.payFast]||'-' }}</span>
          </div> -->
            <div v-if="col.prop === 'truckNo'" class="ellipsis">
              <span class="mr5">{{ row
                .truckNo||'-' }}</span>
              <span :class="$CONSTANT.carTypeColorMap[row.isWx]">{{ $CONSTANT.carTypeMap[row.isWx]||'-' }}</span>
            </div>
            <div v-if="col.prop === 'driverName'" class="ellipsis">
              <span class="mr5">{{ row.driverName||'-' }}</span>
              <span>{{ row.driverPhone||'-' }}</span>
            </div>
            <div v-if="col.prop === 'escortName'" class="ellipsis">
              <span class="mr5">{{ row.escortName||'-' }}</span>
              <span>{{ row.escortPhone||'-' }}</span>
            </div>
            <div v-if="col.prop === 'sourceType'" class="ellipsis">
              <span>平台定向</span>
            </div>
          </template>
        </Table>
        <div class="bottom-submit">
          <div>
            <span>已选择<span class="main-c"> {{ selectData.length||0 }} </span>车</span>
            <span>利润 {{ totalProfit }} 元</span>
            <!-- <span>利润率：{{ profitPercent }}%</span> -->
            <!-- 合计预估利润/货主运价x30吨x承接车数 -->
            <el-button v-if="showActions" type="primary" @click="submit">提交</el-button>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Big from 'big.js';
import supplyTask from '@/api/goodsOrder/supplyTask';
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    orderId: {
      type: [Number, String],
      default() {
        return 1;
      },
    },
    carId: {
      type: [Number, String],
      default() {
        return '';
      },
    },
    // 是否展示操作按钮
    showActions: {
      type: Boolean,
      default() {
        return true;
      },
    },
    orderInformation: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      originInfo: {}, // 承接信息数据
      pushSetBaseInfo: {}, // 推送设置基础数据
      form: {},
      tableCols: [
        { label: '预计利润（元/车）', prop: 'profit', type: 'numberTransition', multiple: 100, width: 138 },
        { label: `上报价格（元/吨）`, prop: 'price', width: 138, type: 'customize' },
        { label: '信息费', prop: 'infoPrice', type: 'customize' },
        // { label: '是否申请卸结', prop: 'payFast', type: 'customize' },
        { label: '车牌号', prop: 'truckNo', type: 'customize' },
        { label: '挂车号', prop: 'guaNo' },
        { label: '驾驶员', prop: 'driverName', type: 'customize', width: 175 },
        { label: '押运员', prop: 'escortName', type: 'customize', width: 175 },
        { label: '预装（吨）', prop: 'goodsWeight', type: 'numberTransition' },
        { label: '上报时间', prop: 'createTime', type: 'filterTime', width: 165 },
        { label: '报车渠道', prop: 'sourceType', type: 'customize', rulesObj: this.$CONSTANT.sourceMap },
        { label: '小五姓名', type: 'objShow', objShow: { keyPar: 'xwUserVO', keyChild: 'name' }},
        { label: '承运商用户', type: 'userInfoCard', userType: 2, width: 165 },
        { label: '提交时间', prop: 'updateTime', type: 'filterTime', width: 165 },
      ],
      selectData: [],
      reportList: [],
    };
  },
  computed: {
    profit() {
      if (this.originInfo.acceptPrice && this.pushSetBaseInfo.price && this.pushSetBaseInfo.priceType === 1) {
        const carryZong = this.pushSetBaseInfo.price;
        const orderZong = this.originInfo.acceptPrice || this.$parseNumber(this.orderInformation.price);
        const priceDiff = orderZong - carryZong;
        const percent = Math.round((priceDiff / orderZong) * 100 * 100) / 100;
        return percent;
      } else {
        return '-';
      }
    },
    // 货源承接-预估利润率（%） ①车队指导价-含税/不含税：（货主运价-车队指导价）/货主运价 ②信息费：信息费/（货主运价*30）
    InfoProfit() {
      if (this.originInfo.acceptPrice && this.pushSetBaseInfo.price && this.pushSetBaseInfo.priceType === 2) {
        const carryZong = this.pushSetBaseInfo.price;
        const orderZong = this.originInfo.acceptPrice * 33 || this.$parseNumber(this.orderInformation.price) * 33;
        const percent = Math.round((carryZong / orderZong) * 100 * 100) / 100;
        return percent;
      } else {
        return '-';
      }
    },
    totalProfit() {
      return this.selectData ? this.selectData.reduce((total, value) => parseFloat(new Big(total).plus(new Big(+value.profit || 0).div(100) || 0)), 0) : 0;
    },
    // 货单利润率:货单预估利润合计/(前线承接价格*已选择车数*33吨车)
    // profitPercent() {
    //   if (this.selectData.length) {
    //     return Math.round((this.totalProfit / (this.originInfo.price * this.selectData.length * 33)) * 100 * 100) / 100;
    //   } else {
    //     return '';
    //   }
    // },
  },

  async created() {
    await this.getOrderGoodsReportDetail();
    await this.getBasePushSet();
    await this.getList();
  },
  methods: {
    // 获取报价详情（获取承接价格和承接车数）
    getOrderGoodsReportDetail() {
      const params = {
        gId: this.orderId,
        type: 1, // type 1前线 2小五 3车队
      };
      supplyTask.orderGoodsReportDetail(params).then(res => {
        if (res) {
          this.originInfo = {
            ...res,
            id: res.id,
            acceptPrice: this.$parseNumber(res.price) || '',
            acceptCarNumber: res.truckNum || 0,
            unit: res.priceUnit,
            needTax: +res.needTax || 0,
          };
        }
      });
    },
    // 推送设置详情
    getBasePushSet() {
      supplyTask.orderGoodsPushDetail(this.orderId).then(res => {
        this.pushSetBaseInfo = {
          priority: res.priority || 3,
          isPushPlatForm: res.isPushPlatForm || 0,
          priceType: res.priceType || 1,
          price: this.$parseNumber(res.price) || 0,
          priceUnit: res.priceUnit || 1,
          needTax: res.needTax || 1,
        };
      });
    },
    getList() {
      const params = {
        search: this.form.truckNo,
        gId: this.orderId,
        state: 1, // 1待提交货主 2待货主确认
        enable: 1, // 没有取消的
      };
      supplyTask.orderCarQxList(params).then(res => {
        this.reportList = res?.records || [];
      }).finally(() => {
        this.tableCols[1].label = `上报价格（${this.$CONSTANT.transportPriceUnitMap.O[+this.pushSetBaseInfo.priceUnit || 1]}）`;
        if (+this.pushSetBaseInfo.priceUnit === 3) {
          this.tableCols[1].width = 153;
        }
      });
    },
    search() {
      this.getList();
    },
    submit() {
      if (!this.selectData.length) {
        this.$baseMessage('请先勾选!', 'error');
        return;
      }
      const params = [{
        id: this.selectData[0].id,
        relId: this.carId,
      }];
      supplyTask.orderOrderCarBatchSubmit(params).then(res => {
        if (res) {
          this.$baseMessage('操作成功！', 'success');
          this.$emit('reassignOtherCarSubmit');
          this.close();
        }
      });
    },
    close() {
      this.$emit('update:dialogVisible', false);
    },
  },
};

</script>
<style lang='scss' scoped>
.carrier-report-wrap{
  .info-item{
    width:350px;
    display: flex;
    line-height: 40px;
  }
  .label{
    text-align: right;
    width:100px;
    color: #A4A4A4;
  }
  .value{
    flex: 1;
  }
  .bold-text{
    font-weight: bold;
    color: $Black;
  }
  .g-search-form-table{
    margin-bottom: 10px;
    .bottom-submit{
      padding-bottom: 30px;
      >div{
        margin-top: 10px;
        >span{
          margin-right: 20px;
        }
        .tip{
          margin-left: 20px;
          margin-right: 0;
          color: $red;
        }
      }
    }
  }
}
</style>
